import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessDeniedComponent } from './shared/components/access-denied/access-denied.component';
import { CallbackComponent } from './callback/callback.component';
import { OktaAuthGuard } from './core/services/okta-auth.guard';
import { AppComponent } from './app.component';


const routes: Routes = [
 // {path: '', component: AppComponent, canActivate: [OktaAuthGuard]},
  {
    path: 'denied',
    component: AccessDeniedComponent,
  },
  {path: 'callback', component: CallbackComponent},
  {
    path: '',
    component: AppComponent,
    canLoad: [OktaAuthGuard],
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
