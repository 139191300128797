import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EnvservService } from './envserv.service';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable({ providedIn: 'root' })
export class OktaAuthService {

  oktaAuth: any;

  public $idToken: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public $accessToken: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public $trackPath: Subject<any> = new Subject();

  $isAuthenticated: Observable<boolean>;
  private observer!: Observer<boolean>;
  constructor(private router: Router, private env: EnvservService) {
    this.oktaAuth = new OktaAuth({
      clientId: this.env.client_id,
      issuer: this.env.issuer,
      redirectUri: this.env.redirect_url,
      pkce: false,
      tokenManager: {
        storage: 'sessionStorage',
        expireEarlySeconds: 300
        // autoRenew: false
      }
    });
    this.$isAuthenticated = new Observable((observer: Observer<boolean>) => {
      this.observer = observer;
      this.isAuthenticated().then(val => {
        observer.next(val);
      });
    });
  }

  getOktaAuth() {


    return this.oktaAuth.tokenManager.get('idToken');
  }

  async isAuthenticated() {
    // Checks if there is a current id in the TokenManger.
    let idToken = await this.oktaAuth.tokenManager.get('idToken');
    let currentTime = new Date().getTime() / 1000;

    if (!idToken?.value || idToken.expiresAt <= currentTime) {
      return false;
    } else{
      this.$idToken.next(idToken);
    }

    return !!(idToken);
  }


  login(originalUrl: any) {
    // Save current URL before redirect
    sessionStorage.setItem('okta-app-url', originalUrl || this.router.url);

    // Launches the login redirect.
    this.oktaAuth.token.getWithRedirect({
      responseType: ['id_token', 'code'],
      responseMode: 'fragment',
      scopes: ['openid', 'profile', 'email']
    });
  }

  async getFreshToken() {
    let token = await this.oktaAuth.tokenManager.get('idToken');
    sessionStorage.removeItem('id_token');
    sessionStorage.removeItem('id_token_expiry');
    sessionStorage.setItem('id_token', btoa(token['idToken']));
    sessionStorage.setItem('id_token_expiry', token['expiresAt'])
  }


  async handleAuthentication() {
    const tokenContainer: any = await this.oktaAuth.token.parseFromUrl();

    this.oktaAuth.tokenManager.add('idToken', tokenContainer.tokens.idToken);
    this.$idToken.next(tokenContainer.tokens.idToken);
    if (await this.isAuthenticated()) {
      if (this.observer) {
        this.observer.next(true);
      }
    }

    // Retrieve the saved URL and navigate back
    const url: any = sessionStorage.getItem('okta-app-url');
    if (url) {
      this.router.navigateByUrl(url);
    } else {
      this.router.navigateByUrl('/home');
    }
  }

  async logout() {
    await this.oktaAuth.signOut({
      postLogoutRedirectUri: this.env.redirect_url
    });
  }
}