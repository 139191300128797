import { Component,  OnDestroy} from '@angular/core';
import { OktaAuthService } from './core/services/okta-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'copi';
  constructor(private auth: OktaAuthService){
    this.auth.$idToken.subscribe(res=>{
      if(res?.idToken){
        sessionStorage.setItem('id_token', btoa(res['idToken']));
        sessionStorage.setItem('id_token_expiry', res['expiresAt'])
      }
    })
  }
  ngOnDestroy(){
    this.auth.$idToken.unsubscribe();
  }
}
