import { Injectable } from '@angular/core';
import {Router } from '@angular/router';
import { EnvservService } from './envserv.service';
import * as AWS from 'aws-sdk';
import { OktaAuthService } from './okta-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService  {
  AccessKeyId;
  SecretKey;
  SessionToken;
  awsRegion;
  identityId;
  accessToken;
  expireTime;
  constructor(private env: EnvservService, private router: Router, private oAuth: OktaAuthService) {}
  // async canLoad(route: Route): Promise<boolean> {
  //   let access: any = await this.authenticate();
  //   //let access = true;
  //   console.log(access);
  //   return access;
  // }
  async authenticate() {
    if (
     
      sessionStorage.getItem('id_token') &&
      new Date() <= new Date(Number(sessionStorage.getItem('id_token_expiry')))
    ) {

      if (!this.expireTime || new Date() > new Date(this.expireTime)) {
        let response = await this.validateWithCognito();
        return response;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  async validateWithCognito() {
    const instance = this;
    let id_token = atob(sessionStorage.getItem('id_token'));
    let logins = {};
    logins[this.env.provider_url] = id_token;
    // Parameters required for CognitoIdentityCredentials
    let params = {
      IdentityPoolId: this.env.pool_id,
      Logins: logins,
    };

    AWS.config.region = this.env.aws_region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);
    let result = await new Promise(function (resolve, reject) {
      (AWS.config.credentials as AWS.Credentials).get((err) => {
        if (err) {
          sessionStorage.removeItem('id_token');
          instance.validateForTokenID();
          resolve(false);
        } else {
          instance.expireTime = (AWS.config
            .credentials as AWS.Credentials).expireTime;
          instance.identityId = (AWS.config
            .credentials as AWS.CognitoIdentityCredentials).identityId;
          instance.AccessKeyId = (AWS.config
            .credentials as AWS.Credentials).accessKeyId;
          instance.SecretKey = (AWS.config
            .credentials as AWS.Credentials).secretAccessKey;
          instance.SessionToken = (AWS.config
            .credentials as AWS.Credentials).sessionToken;
          instance.awsRegion = instance.env.aws_region;
          resolve(true);
        }
      });
    });
    return result;
  }
  validateForTokenID() {
    let callbackUrl = window.location.href;
    let match = callbackUrl.match('id_token=([^&]*)');
    let error = callbackUrl.match('error=([^&]*)');
    callbackUrl.match('error_description=([^&]*)');
    let accessToken = callbackUrl.match('access_token=([^&]*)');
    let id_token;
    let access_token;
    if (match) {
      id_token = match[1];
      access_token = accessToken[1];
    }
    if (id_token) {
      sessionStorage.setItem('id_token', btoa(id_token));
      sessionStorage.setItem('access_token', access_token);
      this.accessToken = access_token;
      let expiry = callbackUrl.match('expires_in=([^&]*)');
      let expiryDate = new Date().setSeconds(
        new Date().getSeconds() + Number(expiry[1])
      );
      sessionStorage.setItem('id_token_expiry', expiryDate.toString());

      if (sessionStorage.getItem('curURL')) {
        window.location.replace(sessionStorage.getItem('curURL'));
      } else {
          this.router.navigate(['/home']);
      }
    }
    else {
      if (error && error[1] === 'access_denied') {
        this.router.navigate(['/denied']);
      } else {
      let url = `${this.env.adfsURL}&client_id=${this.env.client_id}&redirect_uri=${this.env.redirect_url}&nonce=${this.makeid(16)}&state=${this.makeid(16)}`;
      window.location.replace(url);
      }
    }
  }
  makeid(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  getIdtoken() {
    let id_token;
    let callbackUrl = window.location.href;
    let match = callbackUrl.match('id_token=([^&]*)');
    if (match) {
      id_token = match[1];
      sessionStorage.setItem('BridgeId_token', id_token);
    }
  }
  clearsessionStorage() {
    sessionStorage.removeItem('curURL');
    sessionStorage.removeItem('id_token');
  }
}
