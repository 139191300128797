import { Component, OnInit } from '@angular/core';
import { OktaAuthService } from '../core/services/okta-auth.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: []
})
export class CallbackComponent implements OnInit {

  constructor(public authservices:OktaAuthService) { 


  }

  ngOnInit(): void {
    this.authservices.handleAuthentication();

  }

}
