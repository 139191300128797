export class EnvservService {
  public apiUrl = '';
  public enableDebug = true;
  public adfsURL = '';
  public client_id = '';
  public redirect_url = '';
  public aws_region = '';
  public provider_url = '';
  public pool_id = '';
  public adfsBridgeUrl = '';
  public bridgeUrl = '';
  public bridgeApi = '';
  public issuer = ''
}
