import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { EnvServiceProvider } from './core/services/env.service.provider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { load, IntlModule } from '@progress/kendo-angular-intl';
import weekData from 'cldr-data/supplemental/weekData.json';
import numbers from 'cldr-data/main/de/numbers.json';
import timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import calendar from 'cldr-data/main/de/ca-gregorian.json';
import dateFields from 'cldr-data/main/de/dateFields.json';
import { DialogsModule } from '@progress/kendo-angular-dialog';


weekData.supplemental.weekData.firstDay.US = 'mon';

load(
    weekData,
    numbers,
    calendar,
    dateFields,
    timeZoneNames
);
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    IntlModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DialogsModule
  ],
  providers: [EnvServiceProvider, {
    provide: LOCALE_ID, useValue: 'en-GB'
}],
  bootstrap: [AppComponent]
})
export class AppModule { }