import { Injectable } from '@angular/core';
import { Router, CanLoad, Route } from '@angular/router';
import { OktaAuthService } from '../services/okta-auth.service';
import { AuthService } from './auth.service';

@Injectable({providedIn: 'root'})
export class OktaAuthGuard implements  CanLoad {
  constructor(private okta: OktaAuthService, private router: Router, private auth: AuthService) {}

 async canLoad (route: Route){
  const authenticated = await this.okta.isAuthenticated();
    const url: any = sessionStorage.getItem('okta-app-url');
    if (authenticated) {    
       let access: any = await this.auth.validateWithCognito(); 
       console.log(access);
      return access; 
    }
    // Redirect to login flow.
    if(url){
      this.okta.login(url);
    } else{
      this.okta.login(route.path);
    }
    return false;
  }
  
}